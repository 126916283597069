/* Hide the scrollbar track, but still allow scrolling */
::-webkit-scrollbar {
  width: 6px;
  /* Reduce the width to 6px */

}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #6174DD;
  /* Change the background color to #6174DD */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

/* Style the scrollbar track (the non-draggable part) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Change the background color of the track */
}

/* Style the scrollbar corner (where vertical and horizontal scrollbars meet) */
::-webkit-scrollbar-corner {
  background: transparent;
  /* You can set the background color or make it transparent */
}


/* .search-input {
  border-radius: 7px;
  border: 0.5px solid #A0A0A7;
  background: #FFF;
  flex-shrink: 0;
  padding: 11px 14px;
  height: 40px;
  display: flex;
} */
.search-input {
  border-radius: 7px;
  border: 0.5px solid #A0A0A7;
  background: #FFF;
  flex-shrink: 0;
  padding: 11px 14px;
  height: 40px;
  display: flex;
  width: 135px;
}

/* .search-input input { border: none; outline: transparent; padding-left: 12px; height: 100%; overflow: hidden;} */
/* .search-input input {
  border: none;
  outline: transparent;
  padding-left: 12px;
  height: 100%;
  width: 90%;
} */
.search-input input {
  border: none;
  outline: transparent;
  /* padding-left: 12px; */
  /* padding-left: 2px; */
  height: 100%;
  /* height: 150%; */
  width: 100%;
}

.table-container {
  border: none;
  border-collapse: unset;
  border-spacing: 0;
  width: 100%;
}

.table-thead tr {
  border-radius: 8px;
  border-bottom: 0.5px solid #CFCFCF;
  background: #6174DD;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 11px 20px;
  margin-bottom: 16px;
}

.table-tbody tr {
  border-radius: 8px;
  border: 0.5px solid #CFCFCF;
  background: #FAFAFA;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 6px 20px;
  margin-bottom: 8px;
}

/* .view-btn {
  background-color: #6174DD !important;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  border: none;
  padding: 6px 10px;
} */
.view-btn {
  background-color: #6174DD !important;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 10px 17px;
  margin-top:-6.4rem;
  margin-bottom: 3rem;
}

.css-4ijk9-MuiPaper-root-MuiDialog-paper {
  overflow-x: hidden;
}

.css-qnlfbs {
  padding: 0 !important;
}