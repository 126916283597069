/* .chart-container {
    display: flex;
}

.chart-container canvas {
    height: 350px;
    width: 700px;
}

.buttons-container {
    margin: 50px 0;
} */

/* .chart-container {
    display: flex;
}

.chart-container canvas {
    height: 350px;
    width: 700px;
}

.buttons-container {
    margin: 50px 0;
}

.inline-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: 10px;
} */

.chart-container {
    display: flex;
    gap: 6rem;
     /* align-items: center; */
    justify-content: space-between; 
}

.chart-container canvas {
    height: 350px;
    width: 700px;
}

.buttons-container {
    margin: 50px 0;
}

.inline-buttons {
    display: flex;
    gap: 10px;
    /* Space between buttons */
    justify-content: flex-start;
    /* Align buttons to the start */
    flex-wrap: nowrap;
    /* Prevent wrapping */
    margin-top: 10px;
    /* Optional: Adjust margin as needed */
}

.button-64 {
    align-items: center;
    /* background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB); */
    /* background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%); */
    /* background-image: linear-gradient(144deg, #a5b3cd, #5B42F3 50%); */
    /* background-image: linear-gradient(144deg, #5B42F3, #5B42F3 50%); */
    background-image: linear-gradient(144deg, #5B42F3, #5B42F3 50%);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all 300ms ease;
}

.button-64:active,
.button-64:hover {
    outline: 0;
}

.button-64 span {
    /* background-color: rgb(5, 6, 45); */
    font-size: 15px;
    /* padding: 10px 4px; */
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: background 300ms ease;
    color: white;
}

.button-64:hover span {
    background: none;
}

@media (min-width: 768px) {
    .button-64 {
        /* font-size: 24px; */
        /* min-width: 140px; */
        font-size: 15px;
        min-width: 90px;
    }
}

.button-64-active {
    background-image: linear-gradient(144deg, #160840, #160840 50%);
    box-shadow: rgba(255, 140, 0, 0.2) 0 15px 30px -5px;
}