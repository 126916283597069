.customSelect {
    /* position: relative;
    width: 100%;
    max-width: 300px;
    cursor: pointer; */
    /* position: relative; */
    width: 50%;
    max-width: 300px;
    cursor: pointer;
    text-align: center;
    margin-top: 1.7rem;
}

/* .custom-select__header {
    padding: 8px;
    border: 1px solid #808080bf;
    border-radius: 7px;
    background: #FFF;
    cursor: pointer;
} */

.custom-select__header {
    padding: 10px;
    border: 1px solid #E4E4E4;
    /* border: 1px solid #808080bf; */
    border-radius: 7px;
    /* background: rgb(93, 135, 255); */
    cursor: pointer;
    /* color: white; */
    /* background: #D0D0D0; */
    background: #cfcece;
    color: #000000;
}
.custom-select__header:hover {
    background: #6174dd;
    color:#fff;
}

.custom-select__header.focused {
    border-color: #007bff;
    /* color:#fff; */
}

.custom-select__dropdown {
    position: absolute;
    top: 100%;
    /* left: 0; */
    left: -300px;
    width: max-content;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px 10px;
    padding: 10px;
    cursor: pointer;
    margin-top: 1rem;
}

.custom-select__options {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    max-height: 400px;
    cursor: pointer;
}


.custom-select__section-title {
    font-weight: bold;
    margin-bottom: 4px;
    color: red;
    cursor: pointer;
    text-align: start;
}

.custom-select__option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}

.custom-select__option input {
    margin-right: 10px;
    cursor: pointer;
}

.custom-select__clear {
    grid-column: 3;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    margin-top: -0.4rem;
}

.custom-select__clear-btn {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
}

.custom-select__clear-btn:hover {
    background-color: #d32f2f;
}
