/* label {
    display: block;
    margin-top: 3px;
  }
  
  .NewSelectInput-container select{
    padding: 0.9rem;
    font-size: 15px; 
    border: 1px solid #babec5;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box; 
    vertical-align: middle;
    margin-top: 6px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
  }
  
  .NewSelectInput-container select optgroup{
    font-size: 16px;
    font-weight: bold;
    color: #555;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  
  .NewSelectInput-container select:hover {
    border-color: black;
  }
  
  .NewSelectInput-container select:focus {
    border: 2px solid rgb(111, 149, 236);
  }
  
  .NewSelectInput-container {
    position: relative;
    width: 100%;
  }
  
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .dropdown-select{
    width: 100%;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #d05656;
  }
  
  .custom-dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    pointer-events: none;
    transform: translateY(-50%);
  }
  
  select option {
    display: flex;
    justify-content: space-between;
  }
  
  select option::after{
    content: attr(value);
    margin-left: auto;
  }
  
  select option::before {
    content: attr(data-service-name);
  }
   */

.NewSelectInput-container {
  position: relative;
  width: 100%;
}

label {
  /* display: block;
  margin-top: 3px; */
  display: block;
  margin-top: 4px;
  color: black;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 4px;
}

.custom-select-wrapper {
  position: relative;
  width: 100%;
}

.custom-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
  font-size: 15px;
  border: 1px solid #babec5;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-top: 6px;
}

.custom-select:hover {
  border-color: black;
}

.custom-select:focus {
  border-color: rgb(93, 150, 224);
}

.custom-dropdown-icon {
  pointer-events: none;
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #babec5;
  border-radius: 4px;
  background-color: #fff;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-optgroup {
  padding: 0.5rem;
}

.optgroup-label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.custom-option {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
  cursor: pointer;
}

.custom-option:hover {
  background-color: #6174DD;
  color: white;
}

.service-name {
  flex: 1;
}

.service-price {
  margin-left: auto;
  padding-left: 1rem;
}